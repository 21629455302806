import type { IServiceUsageResponse } from '@experiences/interfaces';
import { useApolloTheme } from '@experiences/theme';
import { roundTo2Decimals } from '@experiences/util';
import { Colors } from '@uipath/apollo-core';
import type { LegendRow } from '@uipath/apollo-lab';
import { Chart } from '@uipath/apollo-lab/react';
import type { ApolloThemeType } from '@uipath/portal-shell-util';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import {
    defaultMonths,
    useConsumableToServicesMap,
} from '../../../common/constants/Constant';
import {
    getConsumptionData,
    licenseUsageUrl,
} from '../../../services/licensing/LicenseUsageService';
import { addXAxisFooter } from '../../common/UiBarChart/BarChartContentHelper';
import { UiChartLoading } from '../helperComponents/UiConsumableCard/UiChartLoading';

const indexToColorMap: (themeId: ApolloThemeType) => { [key: number]: string } = (themeId) => {
    const isLight = themeId.includes('light');

    return {
        0: isLight ? Colors.ColorBlueSecondary500 : Colors.ColorBlueSecondary300,
        1: isLight ? Colors.ColorBrandAccessoryPurple : Colors.ColorPurple300,
        2: isLight ? Colors.ColorCodeNumericLightHc : Colors.ColorCodeNumericDarkHc,
        3: isLight ? Colors.ColorOrange500 : Colors.ColorOrange300,
        4: isLight ? Colors.ColorRed500 : Colors.ColorRed300,
        5: isLight ? Colors.ColorGreen500 : Colors.ColorGreen300,
        6: isLight ? Colors.ColorGray500 : Colors.ColorGray300,
    };
};

export const MonthlyConsumptionGraph: React.FC<{
    consumableCode: string;
    tenantId?: string;
    chartDescription?: string;
}> = ({
    consumableCode, tenantId, chartDescription,
}) => {
    const { formatMessage: translate } = useIntl();
    const { themeId } = useApolloTheme();
    const startDate = useMemo(() => {
        const currentDate = new Date();
        const beginningOfMonthOneYearAgoInLocalTime = new Date(currentDate.getUTCFullYear() - 1, currentDate.getUTCMonth() + 1, 1);
        return beginningOfMonthOneYearAgoInLocalTime.getTime() / 1000 - beginningOfMonthOneYearAgoInLocalTime.getTimezoneOffset() * 60;
    }, []);
    const endDate = useMemo(() => {
        const currentDate = new Date();
        const beginningOfNextMonthInLocalTime = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 1, 1);
        return beginningOfNextMonthInLocalTime.getTime() / 1000 - beginningOfNextMonthInLocalTime.getTimezoneOffset() * 60 - 1;
    }, []);
    const { data: consumptionData } = useSWR(
        {
            url: `${licenseUsageUrl}/services`,
            startDate,
            endDate,
            consumableCode,
            services: useConsumableToServicesMap()[consumableCode],
            aggregationPeriod: 'month',
            tenantId,
        },
        getConsumptionData,
    );

    const currentMonth = new Date().getUTCMonth();
    const translatedMonths = useMemo(() => defaultMonths.map((month) => translate({ id: `CLIENT_MONTH_${month}` })), [ translate ]);

    const orderedMonths = useMemo(() => {
        const result = [ ...translatedMonths ];
        result.unshift(...result.splice(currentMonth - 11));
        return result;
    }, [ currentMonth, translatedMonths ]);

    const dateToMonthNumber = useCallback((date: string) => {
        const month = parseInt(date.split('/')[1]);
        return month - 1;
    }, []);

    const consumptionDataWithDefaults: Array<{ service: string; data: number[]; colorIndex: number }> = useMemo(() => {
        if (!consumptionData) {
            return [];
        }

        return consumptionData.servicesUsage.map((serviceUsage: IServiceUsageResponse, index) => {
            const consumptionPerMonth: number[] = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
            serviceUsage.usages.forEach((usage) => {
                consumptionPerMonth[dateToMonthNumber(usage.usageAt)] = roundTo2Decimals(usage.consumedQuantity);
            });

            consumptionPerMonth.unshift(...consumptionPerMonth.splice(currentMonth - 11));
            return {
                service: serviceUsage.serviceName,
                data: consumptionPerMonth,
                colorIndex: index,
            };
        }
        );

    }, [ consumptionData, currentMonth, dateToMonthNumber ]);

    const getServiceName = useCallback((index: number) =>
        translate({ id: `CLIENT_CONSUMABLE_GRAPH_${consumptionDataWithDefaults[index].service}` })
    , [ consumptionDataWithDefaults, translate ]);

    if (!consumptionData?.servicesUsage) {
        return <UiChartLoading
            width={150}
            height={36} />;
    }
    return <Chart
        className={`monthly-consumption-graph-${consumableCode}`}
        data={consumptionDataWithDefaults.map((consumption) => ({
            data: consumption.data,
            renderer: 'line',
            color: indexToColorMap(themeId)[consumption.colorIndex],
            title: translate({ id: `CLIENT_CONSUMABLE_GRAPH_${consumption.service}` }),
            isCompareData: true,
        })
        )}
        labels={orderedMonths}
        legendProperties={{ hasLegend: true }}
        tooltipSchemaBuilder={(values) => {
            const dataValues: LegendRow[] = values.map((value, i) => ({
                cells: [
                    {
                        text: `${getServiceName(i)} ${value.value.y}`,
                        color: value.color,
                    },
                ],
            }));
            addXAxisFooter(dataValues, values);
            return dataValues;
        }}
        alt={`${translate({ id: 'CLIENT_CONSUMABLE_GRAPH_SHORT_NAME' })} - ${chartDescription}`}
    />;
};
