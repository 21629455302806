import type { IConsumable } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import { getDefaultConsumptionPool } from '../../../services/licensing/LicenseUsageService';
import { UiChartLoading } from '../helperComponents/UiConsumableCard/UiChartLoading';
import { DailyConsumptionGraph } from './DailyConsumptionGraph';
import { MonthlyConsumptionGraph } from './MonthlyConsumptionGraph';
import { TotalConsumptionGraph } from './TotalConsumptionGraph';

interface ConsumptionGraphGroup {
    product: string;
    consumable: IConsumable;
    tenantId?: string;
}

interface ICheckboxGroup {
    primary: string;
    secondary: string;
}

interface IGraphComponent {
    [key: string]: { component: React.FC<any>; props: any; validate: () => boolean | undefined | number };
}

enum RadioGroupValue {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

const useStyles = makeStyles(theme =>
    createStyles({
        radioGroup: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            fontSize: '12px',
            fontColor: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 400,
            paddingTop: '11px',
            paddingBottom: '16px',
            '& .MuiRadio-root': {
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        radioGroupLabel: {
            paddingRight: '16px',
            fontSize: '12px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        radioButtonLabel: { marginBottom: 0 },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
    }));

export const ConsumptionGraphGroup: React.FC<ConsumptionGraphGroup> = ({
    product, consumable, tenantId,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const [ startDate, endDate ] = useMemo(() => getDefaultConsumptionPool(consumable), [ consumable ]);
    const [ selectedRadio, setSelectedRadio ] = React.useState<string>(RadioGroupValue.PRIMARY.valueOf());

    const [ translateMap ] = useState<Map<string, ICheckboxGroup>>(
        new Map([
            [
                'AIU',
                {
                    primary: 'CLIENT_CONSUMABLE_TOTAL_CONSUMPTION_PLAN',
                    secondary: 'CLIENT_CONSUMABLE_MONTLHY_CONSUMPTION',
                },
            ],
            [
                'RU',
                {
                    primary: 'CLIENT_CONSUMABLE_MONTLHY_CONSUMPTION',
                    secondary: 'CLIENT_CONSUMABLE_SERVERLESS_CURRENT_MONTH',
                },
            ],
        ]),
    );

    const productGraphComponents: Map<string, IGraphComponent> = new Map([
        [
            'AIU',
            {
                primary: {
                    component: TotalConsumptionGraph,
                    props: {
                        consumableCode: product,
                        startDate,
                        endDate,
                        tenantId,
                        chartDescription: translate({ id: `CLIENT_CONSUMABLE_TOTAL_CONSUMPTION_PLAN` }),
                    },
                    validate: () => startDate && endDate,
                },
                secondary: {
                    component: MonthlyConsumptionGraph,
                    props: {
                        consumableCode: product,
                        tenantId,
                        chartDescription: translate({ id: `CLIENT_CONSUMABLE_MONTLHY_CONSUMPTION` }),
                    },
                    validate: (): boolean => true,
                },
            },
        ],
        [
            'RU',
            {
                primary: {
                    component: MonthlyConsumptionGraph,
                    props: {
                        consumableCode: product,
                        tenantId,
                        chartDescription: translate({ id: `CLIENT_CONSUMABLE_MONTLHY_CONSUMPTION` }),
                    },
                    validate: (): boolean => true,
                },
                secondary: {
                    component: DailyConsumptionGraph,
                    props: {
                        consumableCode: product,
                        services: [ 'serverless' ],
                        startDate,
                        endDate,
                        tenantId,
                        chartDescription: translate({ id: `CLIENT_CONSUMABLE_SERVERLESS_CURRENT_MONTH` }),
                    },
                    validate: () => startDate && endDate,
                },
            },
        ],
    ]) ;

    const getProductGraphComponent = () => {

        let CustomComponent: React.FC<any> = UiChartLoading;
        let props = {
            width: 150,
            height: 36,
        };

        const selectedProductGraphs = productGraphComponents.get(product);

        if (!selectedProductGraphs) {
            return <CustomComponent {...props} />;
        }

        const graphComponent = selectedProductGraphs[selectedRadio];

        if (graphComponent.validate()) {
            CustomComponent = graphComponent.component;
            props = graphComponent.props;
        }

        return <CustomComponent {...props} />;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRadio((event.target as HTMLInputElement).value);
    };

    return (
        <>
            <RadioGroup
                aria-label={translate({ id: `CLIENT_CONSUMABLE_TIMEFRAME_COLON` })}
                data-cy="ui-chart-section-radio-group"
                value={selectedRadio}
                onChange={handleChange}>
                <div className={classes.radioGroup}>
                    <UiText className={classes.radioGroupLabel}>
                        {translate({ id: `CLIENT_CONSUMABLE_TIMEFRAME_COLON` },)}
                    </UiText>
                    <FormControlLabel
                        value={RadioGroupValue.PRIMARY}
                        control={<Radio color="primary" />}
                        label={
                            <div className={classes.switchLabel}>
                                {translate({ id: translateMap.get(product)?.primary })}
                            </div>
                        }
                        data-cy="first-radio-consumption"
                        className={classes.radioButtonLabel}
                    />
                    <FormControlLabel
                        value={RadioGroupValue.SECONDARY}
                        control={<Radio color="primary" />}
                        label={
                            <div className={classes.switchLabel}>
                                {translate({ id: translateMap.get(product)?.secondary })}
                            </div>
                        }
                        data-cy="second-radio-consumption"
                        className={classes.radioButtonLabel}
                    />
                </div>
            </RadioGroup>
            {getProductGraphComponent()}
        </>
    );
};
