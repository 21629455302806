/* eslint-disable max-len */
import { UiText } from '@experiences/ui-common';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import type {
    SxProps,
    Theme,
} from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalChip } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import type { ReactElement } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

interface UiCardThemeProps {
    clickable: boolean;
    disabled: boolean;
}

const useStyles = makeStyles<Theme, UiCardThemeProps>(theme =>
    createStyles({
        card: props => ({
            '&:hover': props.clickable
                ? {
                    boxShadow: `0px 1px 10px ${theme.palette.semantic.colorBorderDeEmp}, 0px 4px 5px ${theme.palette.semantic.colorBorderDeEmp}, 0px 2px 4px -1px ${theme.palette.semantic.colorBorderDeEmp}`,
                    cursor: 'pointer',
                }
                : undefined,
            boxShadow: `${theme.palette.semantic.colorBorderDeEmp} 0px 0px 1px 1px, ${theme.palette.semantic.colorBorderDeEmp} 0px -1px 0px, ${theme.palette.semantic.colorBorderDeEmp} 1px 1px 0px`,
            border: `1px ${theme.palette.semantic.colorBorderDisabled}`,
            backgroundColor: props.disabled ? theme.palette.semantic.colorBackgroundDisabled : '',
        }),
        cardContent: {
            padding: '32px 24px 16px 24px',
            '&:last-child': { paddingBottom: '36px' },
        },
        titleSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '8px 0 12px 0',
        },
        titleText: {
            marginLeft: '12px',
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorForeground,
        },
        descriptionText: {
            fontWeight: 400,
            fontSize: '14px',
        },
        statusChip: {
            marginLeft: '8px',
            marginBottom: '4px',
        },
        unsetBackground: { backgroundColor: 'unset' },
        chip: props => ({
            marginTop: '16px',
            fontSize: '12px',
            fontWeight: 600,
            backgroundColor: props.disabled ? theme.palette.semantic.colorBorderDisabled : `${theme.palette.semantic.colorInfoBackground} !important`,
            color: theme.palette.semantic.colorInfoForeground,
            height: '24px',
        }),
        cardAction: {
            position: 'absolute',
            bottom: '5px',
            right: '0px',
        },
    }),
);

export const UiCard: React.FC<{
    title: string;
    titleIcon: ReactElement<any, any>;
    description: string;
    chipText?: string;
    onClickLink: () => void;
    dataCy: string;
    actions?: ReactElement<any, any>;
    sx?: SxProps;
    style?: React.CSSProperties;
    disabled?: boolean;
    preview?: boolean;
}> = ({
    title, titleIcon, description, chipText, onClickLink, dataCy, actions, sx, style, disabled = false, preview = false,
}) => {

    const classes = useStyles({
        clickable: !!onClickLink && !disabled,
        disabled,
    });
    const { formatMessage: translate } = useIntl();

    return (
        <Card
            className={clsx({
                [classes.card]: true,
                'Mui-disabled': disabled,
            })}
            role="gridcell"
            tabIndex={0}
            sx={sx}
            style={style}
            onClick={() => !disabled && onClickLink()}
            onKeyPress={event => (event.key === 'Enter' || event.code === 'Space')
                && !disabled && onClickLink()}
            data-cy={dataCy}
            aria-label={translate({ id: title })}>
            <CardContent classes={{ root: classes.cardContent }}>
                <Box className={classes.titleSection}>
                    {titleIcon}
                    <div>
                        <UiText className={classes.titleText}>
                            {translate({ id: title })}
                        </UiText>
                    </div>

                    {preview && <Box className={classes.statusChip}>
                        <PortalChip
                            className={classes.unsetBackground}
                            size="normal"
                            type="info"
                            isFocusable={false}
                            text={translate({ id: 'CLIENT_PREVIEW' })}
                        />
                    </Box>}
                </Box>
                <UiText className={classes.descriptionText}>
                    {translate({ id: description })}
                </UiText>
                {chipText && <Chip
                    clickable={false}
                    className={classes.chip}
                    label={translate({ id: chipText })} />}
            </CardContent>
            {actions &&
                <CardActions className={classes.cardAction}>
                    {actions}
                </CardActions>}
        </Card>
    );
};
