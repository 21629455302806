import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiText } from '@experiences/ui-common';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { getTenantConsumables } from '../../services/licensing/LicenseService';
import { getConsumables } from '../../services/licensing/management/AccountService';
import { accountGlobalId } from '../../store/selectors';
import { UiConsumableGroup } from './helperComponents/UiConsumableCard/UiConsumableGroup';
import { generateConsumablesFetchKey } from './subcomponents/LicensingDataFetchKeyGenerators';
import LoadingStateSkeletonComponent from './subcomponents/LoadingStateSkeletonComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '18px',
        },
        content: {
            display: 'flex',
            minHeight: '80px',
            justifyContent: 'space-between',
        },
        subTitle: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '24px',
            marginBottom: '8px',
        },
        noLicensesAvailable: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '24px',
            marginLeft: '24px',
            marginBottom: '6px',
        },
        chart: {
            borderLeft: `1px solid ${theme.palette.semantic.colorBorder}`,
            borderRight: `1px solid ${theme.palette.semantic.colorBorder}`,
            borderBottom: `1px solid ${theme.palette.semantic.colorBorder}`,
        },
    }));

export const ConsumablePanel: React.FC<{
    tenantId?: string;
    isServiceMode: boolean;
}> = ({
    tenantId, isServiceMode,
}) => {
    const enableRobotUnits = useFeatureFlagValue(Features.EnableRobotUnits.name);
    const enableApiCalls = useFeatureFlagValue(Features.EnableApiCallsUsage.name);
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const accountId = useSelector(accountGlobalId);
    const consumablesFetchKey = useMemo(() => generateConsumablesFetchKey(tenantId), [ tenantId ]);

    const { data: consumablesToRender } = useSWR(
        {
            url: consumablesFetchKey,
            accountGlobalId: accountId,
            tenantGlobalId: tenantId,
        },
        (params) => tenantId != null ? getTenantConsumables({
            ...params,
            tenantGlobalId: tenantId,
        }) : getConsumables(params)
    );

    const consumables = useMemo(() => {
        let filteredConsumables = consumablesToRender;

        if (!filteredConsumables) {
            return undefined;
        }

        if (!enableRobotUnits) {
            filteredConsumables = filteredConsumables.filter(p => p.code !== 'RU');
        }

        if (!enableApiCalls) {
            filteredConsumables = filteredConsumables.filter(p => p.code !== 'APICALLS');
        }

        return filteredConsumables;
    }, [ consumablesToRender, enableApiCalls, enableRobotUnits ]);

    const [ chartSection, setChartSection ] = useState<{ opened: boolean; selected: string }>(
        {
            opened: false,
            selected: '',
        });

    const handleOpenChart = useCallback((selected: string) => {
        setChartSection(prevState => ({
            opened: prevState.selected === selected ? !prevState.opened : true,
            selected,
        }));
    }, [ setChartSection ]);

    const consumableProducts = () => {
        if (!consumables) {
            return undefined;
        }

        const grid = consumables.map((consumable, index) => <UiConsumableGroup
            key={index}
            consumable={consumable}
            handleOpenChart={handleOpenChart}
            chartSection={chartSection}
            tenantId={tenantId}
            index={index} />);
        return grid;
    };

    if (consumablesToRender) {
        if (consumablesToRender.length === 0) {
            return (<UiText className={classes.noLicensesAvailable}>
                {translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE' })}
            </UiText>);
        }
        return (
            <>
                <div className={classes.container}>
                    <Grid
                        container
                        spacing={0}>
                        {consumableProducts()}
                    </Grid>
                </div>
            </>);
    }
    return <LoadingStateSkeletonComponent
        childGridColumns={6}
        childGridElements={[ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ]} />;
};
